<template>
  <div>
    <!--Top Page Image Viewer & Contact Methods-->
    <top-page />

    <!--Embroidery Services-->
    <v-container
      fluid
      grid-list-md
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          d-flex
          xs12
          sm9
        >
          <v-card
            flat
            color="grey lighten-5"
          >
            <v-card-title
              primary
              class="headline font-weight-bold secondary--text"
            >
              Embroidery
              Services
            </v-card-title>
            <v-card-text class="subtitle-1">
              We provide contract embroidery to the asi, ppai, screen printing,
              embroidery and trade industry. We operate 150 embroidery heads and specialize in
              large and small orders as well as fulfillment programs with production 17 hours
              daily.  We embroider on apparel, hats, accessories and more. we offer quicker
              turn-around times than most in the industry. we are your production partner.
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          d-flex
          xs12
          sm3
        >
          <v-card
            flat
            color="secondary"
            dark
          >
            <v-card-text>
              <ul>
                <li
                  v-for="(bullet,i) in embroideryPromos"
                  :key="i"
                  class="bulletitem"
                >
                  {{ bullet.text }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <!--Screen Printing Services-->
    <v-container
      fluid
      grid-list-md
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          d-flex
          xs12
          sm9
        >
          <v-card
            flat
            color="grey lighten-5"
          >
            <v-card-title
              primary
              class="headline font-weight-bold secondary--text"
            >
              Screen
              Printing Services
            </v-card-title>
            <v-card-text class="subtitle-1">
              We provide contract screen printing to the asi, ppai, screen
              printing, embroidery and trade industry. We operate 6 screen printing presses with
              production 17 hours daily. we screen print on apparel, accessories and more. We offer
              quicker turn-around times than most in the industry. We offer a full service art
              department and your garments are printed on top of the line screen printing presses using high tensioned screens and high quality inks.
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex
          d-flex
          xs12
          sm3
        >
          <v-card
            flat
            color="secondary"
            dark
          >
            <v-card-text>
              <ul>
                <li
                  v-for="(bullet,i) in screenPromos"
                  :key="i"
                  class="bulletitem"
                >
                  {{ bullet.text }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TopPage from '@/components/TopPage';

export default {
  name: 'Services',
  components: {
    TopPage,
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
    }),
  },
  data() {
    return {
      embroideryPromos: [
        {
          text: '150 OPERATING SEWING HEADS',
        },
        {
          text: '17 HOURS OF PRODUCTION DAILY',
        },
        {
          text: '15 THREAD COLOR CAPABILITY',
        },
        {
          text: 'FREE DIGITIZING ON ORDERS OF 48PCS OR MORE',
        },
        {
          text: 'IN-HOUSE DIGITIZING DEPT.',
        },
        {
          text: 'PERSONALIZATION/NAMES',
        },
        {
          text: 'QUALITY CONTROL DEPARTMENT',
        },
      ],
      screenPromos: [
	      {
		      text: '7 SCREEN PRINTING PRESSES',
	      },
	      {
		      text: '17 HOURS OF PRODUCTION DAILY',
	      },
	      {
		      text: 'SPOT PRINTING',
	      },
	      {
		      text: 'SIMULATED PROCESS PRINTING',
	      },
	      {
		      text: '4 COLOR PROCESS PRINTING',
	      },
	      {
		      text: 'FULL IN-HOUSE ART DEPARTMENT',
	      },
	      {
		      text: 'QUALITY CONTROL DEPARTMENT',
	      },
      ],
    };
  },
};
</script>

<style scoped>
  .bulletitem{
    margin-top:10px;
    margin-right:10px;
  }
  button {
    outline: none;
  }
</style>
